define("discourse/plugins/discourse-post-voting/discourse/components/reviewable-post-voting-comment", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-topic">
    <a class="title-text" href={{this.post.url}}>
      {{html-safe @reviewable.topic.fancyTitle}}</a>
    {{category-badge @reviewable.category}}
  </div>
  
  <div class="post-contents-wrapper">
    <ReviewableCreatedBy @user={{@reviewable.target_created_by}} @tagName="" />
    <div class="post-contents">
      <ReviewablePostHeader
        @reviewable={{@reviewable}}
        @createdBy={{@reviewable.target_created_by}}
        @tagName=""
      />
  
      <div class="post-body">
        {{html-safe (or @reviewable.payload.comment_cooked @reviewable.cooked)}}
      </div>
  
      {{#if @reviewable.payload.transcript_topic_id}}
        <div class="transcript">
          <LinkTo
            @route="topic"
            @models={{array "-" @reviewable.payload.transcript_topic_id}}
            class="btn btn-small"
          >
            {{i18n "review.transcript.view"}}
          </LinkTo>
        </div>
      {{/if}}
  
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "sz2GkK37",
    "block": "[[[10,0],[14,0,\"post-topic\"],[12],[1,\"\\n  \"],[10,3],[14,0,\"title-text\"],[15,6,[30,0,[\"post\",\"url\"]]],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,1,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n  \"],[1,[28,[35,1],[[30,1,[\"category\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[[28,[37,4],[[30,1,[\"payload\",\"comment_cooked\"]],[30,1,[\"cooked\"]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"payload\",\"transcript_topic_id\"]],[[[1,\"      \"],[10,0],[14,0,\"transcript\"],[12],[1,\"\\n        \"],[8,[39,6],[[24,0,\"btn btn-small\"]],[[\"@route\",\"@models\"],[\"topic\",[28,[37,7],[\"-\",[30,1,[\"payload\",\"transcript_topic_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,8],[\"review.transcript.view\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@reviewable\",\"&default\"],false,[\"html-safe\",\"category-badge\",\"reviewable-created-by\",\"reviewable-post-header\",\"or\",\"if\",\"link-to\",\"array\",\"i18n\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/reviewable-post-voting-comment.hbs",
    "isStrictMode": false
  });
  let ReviewablePostVotingComment = _exports.default = (_class = class ReviewablePostVotingComment extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "post", _descriptor2, this);
      this.fetchPost();
    }
    async fetchPost() {
      const post = await this.store.find("post", this.args.reviewable.post_id);
      this.post = post;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "post", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReviewablePostVotingComment);
});